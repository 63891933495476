.input-wrapper {
  position: relative;
  width: "100%";
}

.input-icon {
  position: absolute;
  left: 10px;
  top: calc(
    50% - 0.5em
  ); /* Keep icon in center of input, regardless of the input height */
}

.step-wizard-container {
  width: 100%;
  overflow: hidden;
  height: 100%;
  min-height: 100%;
}

.step-wizard-container > div,
.step-wizard-container > div > div {
  height: 100%; /* example */
}

.avatar-style {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  border: 2px solid rgba(255, 255, 255, 0);
  display: inline-block;
  position: relative;
}

.avatar-style:nth-child(n + 2) {
  margin-left: -20px;
  border: 2px solid white;
}

.avatar-component-style {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  border: 2px solid rgba(255, 255, 255, 0);
  display: inline-block;
  position: relative;
  vertical-align: none !important;
}

.sb-avatar--icon {
  vertical-align: none !important;
}
